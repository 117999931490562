
























import { Component, Vue, Mixins, Prop, Watch } from 'vue-property-decorator'
import ButtonBase from '@/components/atoms/ButtonBase.vue'
import SelectBase from '@/components/atoms/SelectBase.vue'
import ModalBaseMethod from '@/components/molecules/ModalBaseMethod.vue'
import ModalBase from '@/components/molecules/ModalBase.vue'

@Component({
  components: {
    ButtonBase,
    SelectBase,
    ModalBase,
  },
})
export default class ModalConditionExitStudentList extends Mixins(ModalBaseMethod) {
  private selectedSubject = ''
  @Prop()
  private itemOptionDatas!: {
    value: string | number
    text: string
    unit: string
  }[]

  private itemSelectedData = ''

  private itemLimit: string | number = 0

  private id = 0

  private subjectNames = { 国語: 'ko', '算数/数学': 'su', 英語: 'ei', 理科: 'ri', 社会: 'sh' }

  public async setDefaultExitCondition(id: number, subject: string, restrictionName: any, threshold: number) {
    this.itemSelectedData = restrictionName || '問題数'
    this.itemLimit = threshold
    this.selectedSubject = subject
    this.id = id
  }

  get setUnit(): string {
    const itemOption: any = this.itemOptionDatas.find((item) => {
      return this.itemSelectedData === item.value
    })
    return itemOption ? itemOption.unit : '問'
  }
  @Watch('itemLimit')
  private watchInputNumber(newValue: number, oldValue: number) {
    const check = new RegExp(/^\d+$/)
    if (
      newValue + '' === '' ||
      (check.test(newValue + '') &&
        ((this.itemSelectedData == '理解度' && (newValue <= 100 || newValue < oldValue)) ||
          this.itemSelectedData != '理解度'))
    ) {
      this.itemLimit = newValue
    } else {
      this.itemLimit = oldValue
    }
  }

  /**
   * ボタンの色（ボタン操作制御）
   */
  private get colortype(): string {
    const permitted =
      this.itemLimit &&
      +this.itemLimit > 0 &&
      ((this.itemSelectedData == '理解度' && +this.itemLimit <= 100) || this.itemSelectedData != '理解度')

    return permitted ? 'blue' : 'pointer-events-none'
  }

  private update() {
    const params = {
      studentIds: [this.id + ''],
      restriction: this.itemSelectedData,
      subjectCode: this.subjectNames[this.selectedSubject],
      threshold: this.itemLimit,
      isHomework: false,
    }
    Vue.prototype.$http.httpWithToken
      .patch('/studentEndConditions/', params)
      .then(() => {
        alert('更新しました。')
        this.$emit('updateDatas', params)
      })
      .catch(() => {
        alert('更新に失敗しました。')
      })
      .finally(() => {
        this.hide()
      })
  }
}
