



















import { Component, Prop, Vue, Ref, Watch } from 'vue-property-decorator'
import { cloneDeep } from 'lodash'

type Option = { value: number; text: string; checked: boolean }

@Component
export default class MultiSelect extends Vue {
  @Prop({ default: [] })
  optionsProp!: Option[]

  @Prop()
  placeholder?: string

  @Prop({ default: 'top' })
  direction?: 'top' | 'bottom'

  @Ref() multiselectRef: any

  private options = cloneDeep(this.optionsProp)

  private optionsSelected: any = []

  private handleUnCheck(option: Option) {
    this.options = this.options.map((optionItem) => {
      if (optionItem.value === option.value) {
        return { ...optionItem, checked: false }
      } else {
        return optionItem
      }
    })
    this.optionsSelected = this.options.filter(({ checked }) => checked)
    this.$emit('update', this.optionsSelected)
  }

  private handleChange(option: Option) {
    option.checked = !option.checked
    this.optionsSelected = this.options.filter(({ checked }) => checked)
    this.$emit('update', this.optionsSelected)
  }

  private listener(event: any) {
    if (!this.multiselectRef || this.multiselectRef.contains(event.target)) {
      return
    }
    this.isShow = false
  }

  private mounted() {
    this.optionsSelected = this.options.filter(({ checked }) => checked)
    this.$emit('update', this.optionsSelected)

    document.addEventListener('mousedown', this.listener)
    document.addEventListener('touchstart', this.listener)
  }

  private beforeDestroy() {
    document.removeEventListener('mousedown', this.listener)
    document.removeEventListener('touchstart', this.listener)
  }

  private isShow = false

  private toggleDropdown() {
    this.isShow = !this.isShow
  }
}
