








































import { Component, Mixins, Prop, Vue } from 'vue-property-decorator'
import ModalBase from '@/components/molecules/ModalBase.vue'
import ModalBaseMethod from '@/components/molecules/ModalBaseMethod.vue'
import ButtonBase from '@/components/atoms/ButtonBase.vue'
import SelectBase from '@/components/atoms/SelectBase.vue'
import InputWithLabel from '@/components/molecules/InputWithLabel.vue'
import { StudentResponseType } from '@/models/api/students'
import MultiSelect from '@/components/molecules/v3/SelectMultiple.vue'

type StudentResponseTypeV3 = StudentResponseType & {
  userAttributeId: number[]
  userAttributeTitle: string[]
  linkedAttributeTitles: string[]
}

@Component({
  components: {
    ModalBase,
    ButtonBase,
    SelectBase,
    InputWithLabel,
    MultiSelect
  },
})
export default class ModalStudentNameEdit extends Mixins(ModalBaseMethod) {
  private branchId = Vue.prototype.$cookies.get('dataGdls').branchId

  private groupListValue: any = []

  private onSelect(option: any) {
    option.checked = true
  }

  private onRemove(option: any) {
    option.checked = false
  }

  private customLabel({ text }: any) {
    return text
  }

  private onChangeMultiSelect(optionsSelected: any){
    this.groupListValue = optionsSelected
  }

  private logProp(prop: any) {
    console.warn(prop)
    console.log(prop)
    console.warn(prop)
  }

  private isCreate = false

  private id: number | null = null
  private isSent = false

  private nickname = ''
  private studentCode = ''

  @Prop()
  groupOptions!: { text: string; value: number; checked: boolean }[]

  @Prop()
  schoolOptionDatas!: { text: string; value: number }[]
  private schoolSelectedData: number | null = null

  @Prop()
  private gradeOptionDatas!: { text: string; value: number }[]
  private gradeSelectedData: number | null = null

  private get nameErrors(): string[] {
    const errors: string[] = []
    if (!this.nickname) {
      return errors
    }
    if (this.nickname.length > 20) {
      errors.push('氏名は20文字までです')
    }
    return errors
  }

  private get codeErrors(): string[] {
    const errors: string[] = []
    if (!this.studentCode) {
      return errors
    }
    if (this.studentCode.length > 20) {
      errors.push('生徒IDは20文字までです')
    }
    if (!this.studentCode.match(/^[0-9a-zA-Z]*$/)) {
      errors.push('生徒IDは半角英数字で入力してください')
    }
    return errors
  }

  /**
   * ボタンの色（ボタン操作制御）
   */
  private get colortype(): string {
    const permitted =
      this.nickname &&
      this.nameErrors.length === 0 &&
      this.studentCode &&
      this.codeErrors.length === 0 &&
      this.schoolSelectedData &&
      this.gradeSelectedData
    return permitted ? 'blue' : 'pointer-events-none'
  }

  /**
   * タイトル制御
   */
  private get title(): string {
    return this.id ? '生徒編集' : '生徒新規追加'
  }

  public async showModal(student: StudentResponseTypeV3 | null) {
    this.isSent = false
    if (student) {
      this.isCreate = false
      this.id = student.studentId
      this.nickname = student.nickname
      this.studentCode = student.studentCode
      this.schoolSelectedData = student.schoolId
      this.gradeSelectedData = student.gradeId
      this.groupListValue = student.userAttributeId.map((attributeId: number, index: number) => {
        return { checked: true, text: student.userAttributeTitle[index], value: attributeId }
      })
      this.groupOptions = this.groupOptions.map((groupItem) => {
        if (student.userAttributeId.includes(groupItem.value)) {
          return { ...groupItem, checked: true }
        } else {
          return { ...groupItem, checked: false }
        }
      })
    } else {
      this.isCreate = true
      this.id = null
      this.nickname = ''
      this.studentCode = ''
      this.schoolSelectedData = null
      this.gradeSelectedData = null
      this.groupListValue = []
      this.groupOptions = this.groupOptions.map((groupItem) => {
        return { ...groupItem, checked: false }
      })
    }
    this.show()
  }

  private async saveStudent() {
    if (this.isSent) return

    this.isSent = true
    if (this.id) {
      this.updateStudent()
    } else {
      this.createStudent()
    }
  }

  private async loadGroupList() {
    Vue.prototype.$http.httpWithToken
  }

  private async updateStudent() {
    Vue.prototype.$http.httpWithToken
      .patch(`/v3/students/${this.id}`, this.sendParams({ isCreate: false }))
      .then(() => {
        alert('更新しました。')
      })
      .catch((error: any) => {
        if (error.response.data.status === 409) {
          alert('生徒コードが既に存在するため更新できません。')
        } else {
          alert('更新に失敗しました。')
        }
      })
      .finally(() => {
        this.$emit('save-finished')
        this.hide()
      })
  }

  private async createStudent() {
    Vue.prototype.$http.httpWithToken
      .post('/v3/students', this.sendParams({ isCreate: true }))
      .then(() => {
        alert('登録しました。')
      })
      .catch((error: any) => {
        if (error.response.data.status === 409) {
          alert('生徒コードが既に存在するため登録できません。')
        } else {
          alert('登録に失敗しました。')
        }
      })
      .finally(() => {
        this.$emit('save-finished')
        this.hide()
      })
  }
  private sendParams({ isCreate }: { isCreate: boolean }) {
    const params = isCreate
      ? {
          branchId: this.branchId,
          nickname: this.nickname,
          code: this.studentCode,
          schoolId: this.schoolSelectedData,
          gradeId: this.gradeSelectedData,
        }
      : {
          branchId: this.branchId,
          nickname: this.nickname,
          code: this.studentCode,
          schoolId: this.schoolSelectedData,
          gradeId: this.gradeSelectedData,
          student_group_ids: this.groupListValue.map((groupItem: { value: number }) => groupItem.value),
        }
    return params
  }
}
