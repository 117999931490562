























import { Component, Mixins, Vue, Ref } from 'vue-property-decorator'
import ButtonBase from '@/components/atoms/ButtonBase.vue'
import SelectBase from '@/components/atoms/SelectBase.vue'
import TableBase from '@/components/atoms/v3/TableBase1110.vue'
import ModalBase from '@/components/molecules/ModalBase.vue'
import ModalBaseMethod from '@/components/molecules/ModalBaseMethod.vue'
import ModalConditionExit from '@/components/organisms/v3/mode/ModalConditionExitStudentList.vue'
import ModalSetDifficult from '@/components/organisms/v3/mode/ModalSetDifficult.vue'
import {
  DIFFICULT_TYPES,
  DifficultTableItem,
  ActiveSubject,
  DifficultInitial,
  Subject,
} from './mode/LevelDifficult.vue'
import RadioGroup from '@/components/molecules/RadioGroup.vue'

@Component({
  components: {
    ButtonBase,
    SelectBase,
    TableBase,
    ModalBase,
    ModalConditionExit,
    ModalSetDifficult,
    RadioGroup,
  },
})
export default class ModalStudentEndCondition extends Mixins(ModalBaseMethod) {
  private id = 0

  @Ref()
  modalCondition!: ModalConditionExit
  @Ref()
  modalSetDifficult!: ModalSetDifficult

  private itemOptionDatas: { value?: number; text?: string; unit?: string }[] = []
  private branchId = Vue.prototype.$cookies.get('dataGdls').branchId
  private studentDifficult: { EN: DifficultTableItem[]; RK: DifficultTableItem[] } = { [DIFFICULT_TYPES.exercise]: [], [DIFFICULT_TYPES.test]: [] }

  private updateDatas() {
    this.showModal(this.studentData.id, this.studentData.studentId)
  }

  private difficultTypeRadioItems = [
    { label: '理解度テスト', value: DIFFICULT_TYPES.test },
    { label: '演習問題', value: DIFFICULT_TYPES.exercise },
  ]

  private subjects = [
    {
      id: 1,
      name: '算数/数学',
      code: 'su',
      sort_num: 1,
    },
    {
      id: 2,
      name: '英語',
      code: 'ei',
      sort_num: 2,
    },
    {
      id: 3,
      name: '国語',
      code: 'ko',
      sort_num: 3,
    },
    {
      id: 4,
      name: '理科',
      code: 'ri',
      sort_num: 4,
    },
    {
      id: 5,
      name: '社会',
      code: 'sh',
      sort_num: 5,
    },
  ]

  private difficultType = DIFFICULT_TYPES.test

  private loadRestrictions() {
    Vue.prototype.$http.httpWithToken.get('/restrictions').then((res: any) => {
      this.itemOptionDatas =
        res.data.restrictions.map((restriction: { id: number; name: string; unit: string }) => {
          return {
            value: restriction.name,
            text: restriction.name,
            unit: restriction.unit,
          }
        }) || []
    })
  }
  private getDifficultItem(
    difficultType: typeof DIFFICULT_TYPES.test | typeof DIFFICULT_TYPES.exercise,
    subject: Subject,
    difficultData: DifficultInitial[],
    activeSubjects: ActiveSubject[]
  ): DifficultTableItem {
    const currentDifficult = difficultData.find(
      (difficult) => difficult.class_category_code === difficultType && difficult.subject_code === subject.code
    )
    const isSubjectOff = !activeSubjects.find((activeSubject) => activeSubject.subjectCode === subject.code)
    return {
      difficult: '難易度',
      subjectCode: subject.code,
      subjectId: subject.id,
      subjectName: subject.name,
      difficult1: {
        value: 1,
        checked: Boolean(currentDifficult?.levels.includes(1)),
        label: '1',
        disabled: true,
        isEmpty: isSubjectOff
      },
      difficult2: {
        value: 2,
        checked: Boolean(currentDifficult?.levels.includes(2)),
        label: '2',
        disabled: true,
        isEmpty: isSubjectOff
      },
      difficult3: {
        value: 3,
        checked: Boolean(currentDifficult?.levels.includes(3)),
        label: '3',
        disabled: true,
        isEmpty: isSubjectOff
      },
      setDifficult: [
        {
          onclick: () => this.showSetDifficultModal(subject, currentDifficult?.levels || [], difficultType),
          name: '編集',
          disabled: isSubjectOff,
        },
      ],
    }
  }

  private async loadDifficultData(difficultData: DifficultInitial[], subjectData: ActiveSubject[]) {
    this.studentDifficult = { [DIFFICULT_TYPES.exercise]: [], [DIFFICULT_TYPES.test]: [] }
    this.subjects.forEach((subject: Subject) => {
      this.studentDifficult[DIFFICULT_TYPES.exercise].push(
        this.getDifficultItem(DIFFICULT_TYPES.exercise, subject, difficultData, subjectData)
      )
      this.studentDifficult[DIFFICULT_TYPES.test].push(
        this.getDifficultItem(DIFFICULT_TYPES.test, subject, difficultData, subjectData)
      )
    })
  }

  private studentData = {
    name: '',
    gdlsCode: '',
    id: 0,
    studentId: 0,
  }
  private endConditionTableButtons = ['setCondition']
  private difficultTableButtons = ['setDifficult']
  private difficultTableCheckBoxs = ['difficult1', 'difficult2', 'difficult3']

  private studentEndConditions: {
    subjectName: string
    enCondition: string
    syCondition: string
  }[] = []

  private endConditionTableFields = [
    { key: 'subjectName', label: '教科' },
    { key: 'enCondition', label: '演習終了条件' },
    { key: 'setCondition', label: '' },
  ]

  private difficultTableFields = [
    { key: 'difficult', label: '難易度設定' },
    { key: 'difficult1', label: '' },
    { key: 'difficult2', label: '' },
    { key: 'difficult3', label: '' },
    { key: 'setDifficult', label: '' },
  ]

  private showSetStudentEndCondition({ subjectName, enCondition, userId }: any) {
    const getThreshHold = () => +enCondition?.match(/\d+/)[0]
    const getRestrictId = () => {
      const restrictionName = enCondition?.split('')[enCondition?.split('').length - 1]
      return this.itemOptionDatas.find((item) => item.unit === restrictionName)?.value
    }

    this.modalCondition.setDefaultExitCondition(userId, subjectName, getRestrictId(), getThreshHold())
    this.modalCondition.show()
  }

  private showSetDifficultModal(subject: Subject, levels: number[], difficultType?: string) {
    this.modalSetDifficult.setDefaultDifficult(subject, levels, difficultType, this.studentData.studentId)
    this.modalSetDifficult.show()
  }

  public async showModal(id: number, studentId: number, isReset?: boolean) {
    try {
      if (isReset) {
        this.difficultType = DIFFICULT_TYPES.test
      }
      const GDLS_SERVICE = 'GDLS'
      const difficultPromise = Vue.prototype.$http.httpWithToken.get('/v3/student_difficulty_settings', {
        params: { student_id: studentId },
      })
      const subjectPromise = Vue.prototype.$http.httpWithToken.get('/v3/active_subjects', {
        params: { branch_id: this.branchId, service_code: GDLS_SERVICE, student_id: studentId },
      })
      const endConditionPromise = Vue.prototype.$http.httpWithToken.get(`/studentEndConditions`, {
        params: { userId: id },
      })
      await Promise.all([difficultPromise, subjectPromise, endConditionPromise]).then(
        ([difficultResult, subjectResult, endConditionResult]) => {
          const endConditionData = endConditionResult.data
          this.studentData.id = id
          this.studentData.studentId = studentId
          this.studentData.name = endConditionData.nickname
          this.studentData.gdlsCode = endConditionData.gdlsCode

          this.loadEndConditionData(endConditionResult.data.endConditions, id)
          this.loadDifficultData(difficultResult.data.student_difficulty_settings, subjectResult.data)
        }
      )
      this.show()
    } catch (error) {
      alert('終了条件の取得に失敗しました。更新して再度お試しください。')
    }
  }

  private loadEndConditionData(endConditionData: any, id: number) {
    this.studentEndConditions = endConditionData.map(
      ({ subjectName, enCondition }: { subjectName: string; enCondition: string }) => {
        return {
          subjectName,
          enCondition: enCondition ? enCondition : '-',
          setCondition: [
            {
              onclick: () => this.showSetStudentEndCondition({ userId: id, subjectName, enCondition }),
              name: '編集',
            },
          ],
        }
      }
    )
  }

  private mounted() {
    this.loadRestrictions()
  }
}
