





















































































import { Component, Ref, Mixins, Vue } from 'vue-property-decorator'
import TitleBase from '@/components/atoms/TitleBase.vue'
import TitleTextBase from '@/components/atoms/TitleTextBase.vue'
import ColoredBox from '@/components/atoms/ColoredBox.vue'
import ButtonBase from '@/components/atoms/ButtonBase.vue'
import InputWithLabel from '@/components/molecules/InputWithLabel.vue'
import SelectBase from '@/components/atoms/SelectBase.vue'
import TableBase from '@/components/atoms/v3/TableBase1110.vue'
import ModalStudentStatus from '@/components/organisms/ModalStudentStatus.vue'
import ModalStudentEdit from '@/components/organisms/v3/ModalStudentEdit.vue'
import ModalStudentEndCondition from '@/components/organisms/v3/ModalStudentEndCondition.vue'
import QueryMethods from '@/components/atoms/QueryMethods.vue'
import { StudentResponseType, GetStudentsResponseType } from '@/models/api/students'
import FileDropArea from '@/components/atoms/FileDropArea.vue'
import ModalCsvLoadErrors, { CsvLoadError } from '@/components/organisms/ModalCsvLoadErrors.vue'
import GlinkBranchControlable from '@/mixins/teacher/GlinkBranchControlable'
import LoadClassOptionsApi from '@/mixins/teacher/LoadClassOptionsApi'
import LoadStudentsApi from '@/mixins/teacher/LoadStudentsApi'
import { TEACHER_SETTING_TEXT } from '@/constants'

type StudentResponseTypeV3 = StudentResponseType & {
  userAttributeId: number[]
  userAttributeTitle: string[]
  linkedAttributeTitles: string[]
}

@Component({
  components: {
    TitleBase,
    TitleTextBase,
    ColoredBox,
    ButtonBase,
    InputWithLabel,
    SelectBase,
    TableBase,
    ModalStudentStatus,
    ModalStudentEndCondition,
    ModalStudentEdit,
    FileDropArea,
    ModalCsvLoadErrors,
  },
})
export default class StudentInformation extends Mixins(
  QueryMethods,
  GlinkBranchControlable,
  LoadClassOptionsApi,
  LoadStudentsApi
) {
  // CSVアップロードエリア表示ステータス
  private isShowCsvDropArea = false
  private isKnewtonMaintenanceMode = true

  /**
   * ファイルドロップイベント
   */
  private async onDropFile(file: File) {
    if (await this.isMaintenanceMode()) return
    Vue.prototype.$logger.log('onDropFile')
    this.uploadStudentsCsv(file)
  }

  /**
   * 生徒一覧CSVを元に
   */
  private uploadStudentsCsv(file: File) {
    const headers = { 'Content-Type': 'multipart/form-data' }
    const params = new FormData()
    params.append('csvFile', file)
    params.append('branchId', this.branchId)

    this.isProcessing = true
    Vue.prototype.$loading.start()

    Vue.prototype.$http.httpWithToken
      .post('/students/importCsv', params, { headers: headers })
      .then(() => {
        Vue.prototype.$logger.log('uploadStudentsCsv success')
        alert('生徒登録が完了しました。')
      })
      .catch((error: any) => {
        if (error?.response?.data?.status === 400) {
          const errors: CsvLoadError[] = error?.response?.data?.errors || []
          this.showCsvLoadErrors(errors)
        }
      })
      .finally(() => {
        Vue.prototype.$loading.complete()
        this.isShowCsvDropArea = false
        this.search()
        this.isProcessing = false
      })
  }

  private branchId = Vue.prototype.$cookies.get('dataGdls').branchId

  private searchCount = 0
  private isProcessing = false

  private breadcrumbs = [
    { text: TEACHER_SETTING_TEXT, href: '/teacher/setting/top' },
    { text: '生徒', active: true },
  ]

  private nickname = ''
  private studentId = ''

  private schoolOptionDatas: { text: string; value: number }[] = []
  private schoolSelectedData: number | null = null

  private gradeOptionDatas: { text: string; value: number }[] = []
  private gradeSelectedData: number | null = null

  private classOptionDatas: { text: string; value: string }[] = []
  private classSelectedData: string | null = null

  private attributeOptionDatas: { text: string; value: number; checked: boolean }[] = []
  private attributeSelectedData: number | null = null

  private selectedId: number[] = []

  private tableBaseItems: {
    id: { value: number; checked: boolean }
    name: { onclick: Function; variable: StudentResponseType; name: string; disabled: boolean }
    gdlsCode: { onclick: Function; variable: StudentResponseType; name: string }[]
    school: string
    grade: string
    attribute: any
    status: { onclick: Function; variable: number; name: string }[]
    condition: { onclick: Function; variable: { id: number, studentId: number }; name: string }[]
    homeStudy: { checked: boolean; id: string; onChange: any }
    gid: string
    suspend: { checked: boolean; id: string; onChange: any }
    delete: { onclick: Function; variable: number; name: string }[]
  }[] = []

  private tableBaseFields = [
    { key: 'id', label: '' },
    { key: 'name', label: '氏名・ID' },
    { key: 'gdlsCode', label: 'ログインコード' },
    { key: 'school', label: '学校' },
    { key: 'grade', label: '学年' },
    { key: 'attribute', label: 'グループ' },
    { key: 'status', label: '授業情報' },
    { key: 'condition', label: '学習条件' },
    { key: 'homeStudy', label: '家庭学習' },
    { key: 'gid', label: 'G-ID' },
    { key: 'suspend', label: '休止' },
    { key: 'delete', label: '削除' },
  ]

  private tableBaseButtons = ['gdlsCode', 'status', 'condition', 'delete']

  private tableBaseCheckboxs = ['id']

  private tableBaseToglles = ['homeStudy', 'suspend']

  private teacherTableBaseLinks = ['name']

  private tableBaseListText = ['attribute']

  @Ref() modalStudentStatus!: ModalStudentStatus

  @Ref() modalStudentEndCondition!: ModalStudentEndCondition

  @Ref() modalStudentEdit!: ModalStudentEdit

  @Ref() modalCsvLoadErrors!: ModalCsvLoadErrors

  private showStudentStatus(id: number): void {
    this.modalStudentStatus.showModal(id)
  }

  private showStudentEndCondition({ id, studentId }: { id: number; studentId: number }): void {
    this.modalStudentEndCondition.showModal(id, studentId, true)
  }

  private async showStudentEdit(student: StudentResponseTypeV3 | null = null) {
    if (student == null) {
      if (await this.isMaintenanceMode()) return
    }
    this.modalStudentEdit.showModal(student)
  }

  private showCsvLoadErrors(errors: CsvLoadError[]): void {
    this.modalCsvLoadErrors.showErrors(errors)
  }

  private updateGdlsCode(student: StudentResponseType): void {
    this.reissueGdls(student.id)
  }

  /**
   * GDLSコード再発行
   */
  private async reissueGdls(id: number) {
    if (this.isProcessing) return

    if (!confirm('再発行しますか？')) return

    this.isProcessing = true

    await Vue.prototype.$http.httpWithToken
      .post(`/users/${id}/gdlsCode`)
      .then(() => {
        alert('GDLSコード再発行が完了しました。')
      })
      .catch((error: any) => {
        if (error.response.data.status === 404) {
          alert('データが見つかりません。ページを更新してお確かめください。')
        }
      })
      .finally(() => {
        this.search()
        this.isProcessing = false
      })
  }

  private deleteStudent(id: number): void {
    if (this.isProcessing) return

    if (!confirm('削除しますか？')) return

    this.isProcessing = true

    Vue.prototype.$http.httpWithToken
      .delete(`/users/${id}`)
      .then(() => {
        alert('削除しました。')
      })
      .catch(() => {
        alert('削除に失敗しました。')
      })
      .finally(() => {
        this.search()
        this.isProcessing = false
      })
  }

  private edit() {
    this.selectedId = this.tableBaseItems
      .filter((item) => item.id.checked)
      .map((item) => {
        return item.id.value
      })
    if (this.selectedId.length == 0) {
      alert('対象の生徒を選択してください。')
      return
    }
    const url = new URL(window.location.href)
    const search = encodeURIComponent(url.search)
    const id = this.selectedId.join(',')
    const isV3 = Vue.prototype.$gdlsCookiesV3.isV3()

    const jumpto = `/teacher/setting/${isV3 ? 'v3/' : ''}student/edit?id=${id}&search=${search}`
    window.location.href = jumpto
  }

  private async mounted() {
    Vue.prototype.$loading.start()
    await this.loadInitialize()
    Vue.prototype.$loading.complete()
  }

  // クエリから検索条件を復元
  private setParamsFromQuery() {
    const params = this.getParamsObject()
    // 氏名
    if (params['name']) {
      this.nickname = params['name']
    }
    // ID
    if (params['id']) {
      this.studentId = params['id']
    }
    // 学校
    if (params['school']) {
      this.schoolSelectedData = params['school']
    }
    // 学年
    if (params['grade']) {
      this.gradeSelectedData = params['grade']
    }
    // クラス
    if (params['class']) {
      this.classSelectedData = params['class']
    }
    // 属性
    if (params['attribute']) {
      this.attributeSelectedData = params['attribute']
    }
  }

  // クエリに検索条件を設定
  private setQueryFromParams() {
    const params = {}
    // 氏名
    if (this.nickname) {
      params['name'] = this.nickname
    }
    // ID
    if (this.studentId) {
      params['id'] = this.studentId
    }
    // 学校
    if (this.schoolSelectedData) {
      params['school'] = this.schoolSelectedData
    }
    // 学年
    if (this.gradeSelectedData) {
      params['grade'] = this.gradeSelectedData
    }
    // クラス
    if (this.classSelectedData) {
      params['class'] = this.classSelectedData
    }
    // 属性
    if (this.attributeSelectedData) {
      params['attribute'] = this.attributeSelectedData
    }
    this.setUrlAsParams(params)
  }

  // 初回情報読み込み
  private async loadInitialize() {
    // 学研連携対象の教室であれば、isGlinkBranch:true となる。各種生徒系の編集制御に使用
    await this.isGlinkBranchApi(this.branchId)
    this.setParamsFromQuery()
    await this.loadSelectDatas()
    await this.loadStudentDatas()
    await this.loadKnewtonMaintenance()
  }

  // プルダウン用情報読み込み
  private async loadSelectDatas() {
    this.loadGrades()
    this.loadAttributes()
    this.loadSchools()
    this.loadClasses()
  }

  // 学年情報読み込み
  private async loadGrades() {
    Vue.prototype.$http.httpWithToken.get(`/grades`).then((res: any) => {
      this.gradeOptionDatas = res.data.map((grade: { id: number; name: string; code: string; sortNum: string }) => {
        return {
          text: grade.name,
          value: grade.id,
        }
      })
    })
  }

  // 属性情報読み込み
  private async loadAttributes() {
    // 属性プルダウンの情報設定
    Vue.prototype.$http.httpWithToken
      .get(`/v3/student_groups`, { params: { branchId: this.branchId, withLinked: true } })
      .then((res: any) => {
        this.attributeOptionDatas = res.data.studentGroups.map((userAttributes: { id: number; title: string }) => {
          return {
            text: userAttributes.title,
            value: userAttributes.id,
            checked: false,
          }
        })
      })
  }

  // 学校情報読み込み
  private async loadSchools() {
    Vue.prototype.$http.httpWithToken.get(`/schools`, { params: { branchId: this.branchId } }).then((res: any) => {
      this.schoolOptionDatas = res.data.schools.map((school: { id: number; name: string }) => {
        return {
          text: school.name,
          value: school.id,
        }
      })
    })
  }

  // 授業情報取得
  private async loadClasses() {
    this.classOptionDatas = await this.loadClassOptions(this.branchId)
  }

  // 検索
  private search() {
    this.setQueryFromParams()
    this.loadStudentDatas()
  }

  private getSearchParams(): any {
    const params = this.getSearchParamsBase(this.branchId)

    // 氏名
    if (this.nickname) {
      params['name'] = this.nickname
    }
    // ID
    if (this.studentId) {
      params['studentCode'] = this.studentId
    }
    // 学校
    if (this.schoolSelectedData) {
      params['schoolId'] = this.schoolSelectedData
    }
    // 学年
    if (this.gradeSelectedData) {
      params['gradeId'] = this.gradeSelectedData
    }
    // クラス
    if (this.classSelectedData) {
      const classData = this.classSelectedData.split(',')
      params['isGroup'] = classData[0]
      params['classSettingId'] = classData[1]
      if (classData.length > 2) {
        params['period'] = classData[2]
      }
    }
    // 属性
    if (this.attributeSelectedData) {
      params['userAttributeId'] = this.attributeSelectedData
    }
    return params
  }
  protected async loadStudentsV3(params: any) {
    const res = await Vue.prototype.$http.httpWithToken.get(`/v3/students`, {
      params: params,
    })

    return res.data
  }

  // 生徒情報読み込み・検索
  private async loadStudentDatas() {
    // 固定パラメータ設定
    const params = this.getSearchParams()
    // 生徒情報を取得
    const data: GetStudentsResponseType = await this.loadStudentsV3(params)

    this.searchCount = data.count
    this.tableBaseItems = data.students.map((student) => {
      return {
        id: {
          value: student.id,
          checked: false,
        },
        name: {
          onclick: this.showStudentEdit,
          variable: student,
          name: `${student.nickname}/${student.studentCode}`,
          disabled: this.isGlinkBranch,
        },
        gdlsCode: [{ onclick: this.updateGdlsCode, variable: student, name: '再発行' }],
        school: student.schoolName,
        grade: student.gradeName,

        attribute: { show: false, list: student.userAttributeTitle },
        status: [{ onclick: this.showStudentStatus, variable: student.id, name: '確認' }],
        condition: [
          {
            onclick: this.showStudentEndCondition,
            variable: { id: student.id, studentId: student.studentId },
            name: '設定変更',
          },
        ],
        homeStudy: {
          onChange: this.changeStudentHomeLearning(student.studentId, student.isHomeLearningEnabled),
          checked: student.isHomeLearningEnabled,
          id: `isHome_${student.studentId}`,
        },
        gid: student.isGidRegistered ? '済' : '未',
        suspend: {
          onChange: this.changeSuspendStatus(student.studentId, student.isSuspend),
          checked: student.isSuspend,
          id: `isSuspend_${student.studentId}`,
        },
        delete: [{ onclick: this.deleteStudent, variable: student.id, name: '削除' }],
      }
    })
  }

  private changeStudentHomeLearning(id: number, isEnabled: boolean): { [key: string]: any } {
    return {
      func: this.updateStudentHomeLearning,
      funcParams: {
        id: id,
        isEnabled: !isEnabled,
      },
    }
  }

  // Knewtonメンテナンスモードかの取得
  private async loadKnewtonMaintenance() {
    await Vue.prototype.$http.httpWithToken.get('/knewtonMaintenances/maintenance').then((res: any) => {
      this.isKnewtonMaintenanceMode = res.data.ongoing
    })
  }

  // Knewtonメンテナンスモードか否かの判定
  private async isMaintenanceMode() {
    // 既にメンテナンスモードの場合は即リターン
    if (this.isKnewtonMaintenanceMode) return this.isKnewtonMaintenanceMode

    // 非メンテナンスモード時は再取得し、アラートを表示してからリターン
    await this.loadKnewtonMaintenance()

    if (this.isKnewtonMaintenanceMode) {
      alert('現在メンテナンス中のため、生徒の登録機能はご利用いただけません。')
    }

    return this.isKnewtonMaintenanceMode
  }

  // アップロードボタン押下時イベント
  private async expandUploadArea() {
    if (await this.isMaintenanceMode()) return
    this.isShowCsvDropArea = !this.isShowCsvDropArea
  }

  // 家庭学習ステータス更新
  private async updateStudentHomeLearning(params: { id: number; isEnabled: boolean }) {
    if (this.isProcessing) return
    this.isProcessing = true

    Vue.prototype.$http.httpWithToken
      .patch(`/students/${params.id}`, { branchId: this.branchId, isHomeLearningEnabled: params.isEnabled })
      .then(() => {
        alert('更新しました。')
      })
      .catch(() => {
        alert('更新に失敗しました。')
      })
      .finally(() => {
        this.search()
        this.isProcessing = false
      })
  }

  // 生徒休止トグル ON/OFF
  private changeSuspendStatus(id: number, isEnabled: boolean) {
    return {
      func: this.updateSuspendStatus,
      funcParams: {
        id: id,
        isEnabled: !isEnabled,
      },
    }
  }

  // 生徒停止/再開リクエスト
  private async updateSuspendStatus(params: { id: number; isEnabled: boolean }) {
    if (this.isProcessing) return
    this.isProcessing = true

    Vue.prototype.$http.httpWithToken
      .patch(`/students/${params.id}`, { branchId: this.branchId, isSuspend: params.isEnabled })
      .then(() => {
        alert('更新しました。')
      })
      .catch(() => {
        alert('更新に失敗しました。')
      })
      .finally(() => {
        this.search()
        this.isProcessing = false
      })
  }

  private curriculumSet() {
    this.selectedId = this.tableBaseItems
      .filter((item) => item.id.checked)
      .map((item) => {
        return item.id.value
      })
    if (this.selectedId.length == 0) {
      alert('対象の生徒を選択してください。')
      return
    }
    const url = new URL(window.location.href)
    const search = encodeURIComponent(url.search)
    const id = this.selectedId.join(',')
    const jumpto = `/teacher/setting/student/curriculum?id=${id}&search=${search}`

    window.location.href = jumpto
  }

  /**
   * 選択されている生徒のGDLSコードのPDFダウンロード
   */
  private async downloadPdf() {
    this.selectedId = this.tableBaseItems
      .filter((item) => item.id.checked)
      .map((item) => {
        return item.id.value
      })
    if (this.selectedId.length == 0) {
      alert('対象の生徒を選択してください。')
      return
    }
    const params = {
      branchId: this.branchId,
      userId: this.selectedId.join(','),
    }
    const endPoint = '/v3/students/downloadPdf'
    await Vue.prototype.$http.httpWithToken

      .get(endPoint, {
        responseType: 'blob',
        dataType: 'binary',
        headers: {
          Accept: 'application/pdf',
        },
        params: params,
      })
      .then((res: any) => {
        const blob = new Blob([res.data], { type: 'application/pdf' })
        const a = document.createElement('a')
        a.href = window.URL.createObjectURL(blob)
        a.download = this.getFilename(res.headers['content-disposition'])
        a.click()
      })
      .catch((error: any) => {
        alert('エラーが発生しました')
      })
  }

  /**
   * Content-Dispositionヘッダーからfilenameを取得する
   */
  private getFilename(contentDisposition: string): string {
    const filenameRegex = /filename\*?=['"]?(?:UTF-\d['"]*)?([^;\r\n"']*)['"]?;?/
    const matches = filenameRegex.exec(contentDisposition)
    if (matches != null && matches[1]) {
      return decodeURI(matches[1].replace(/['"]/g, '').replace('utf-8', ''))
    } else {
      alert('ファイル名の取得に失敗しました')
      return 'file_name_error.pdf'
    }
  }
}
