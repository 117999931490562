
















import { Component, Mixins, Vue } from 'vue-property-decorator'
import CheckboxSquare from '@/components/atoms/CheckboxSquare.vue'
import ModalBaseMethod from '@/components/molecules/ModalBaseMethod.vue'
import ModalBase from '@/components/molecules/ModalBase.vue'
import ButtonBase from '@/components/atoms/ButtonBase.vue'
import { Subject } from './LevelDifficult.vue'

@Component({
  components: {
    CheckboxSquare,
    ModalBase,
    ButtonBase
  },
})
export default class ModalSetDifficult extends Mixins(ModalBaseMethod) {
  private difficultOptions = [
    {
      name: '難易度１',
      value: 1,
      checked: false,
    },
    {
      name: '難易度２',
      value: 2,
      checked: false,
    },
    {
      name: '難易度３',
      value: 3,
      checked: false,
    },
  ]

  private close() {
    this.hide()
  }
  private update() {
    const difficultDataUpdate = {
      student_id: this.studentId,
      class_category_code: this.difficultType,
      subject_id: this.subject.id,
      levels: this.difficultOptions
        .filter((difficultOption) => difficultOption.checked)
        .map((difficultOption) => difficultOption.value),
    }
    Vue.prototype.$http.httpWithToken
      .post('/v3/student_difficulty_settings', difficultDataUpdate)
      .then(() => {
        alert('更新しました。')
        this.$emit('updateDatas')
      })
      .catch(() => {
        alert('更新に失敗しました。')
      })
      .finally(() => {
        this.hide()
      })
  }
  private subject: Partial<Subject> = {}
  private difficultType = ''
  private studentId = -1

  public setDefaultDifficult(
    subjectParam: Subject,
    levels: number[],
    difficultTypeParam?: string,
    studentIdParam?: number
  ) {
    this.subject = subjectParam
    this.difficultType = difficultTypeParam || ''
    this.studentId = studentIdParam || -1
    this.difficultOptions = this.difficultOptions.map((difficultOption) => {
      if (levels.includes(difficultOption.value)) {
        return { ...difficultOption, checked: true }
      }
      return { ...difficultOption, checked: false }
    })
  }
}
